require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import "bootstrap";

$(function() {
  $(".toggle").on("click", function() {
    if ($(".item").hasClass("active")) {
      $(".item").removeClass("active");
      $(this).find("a").html("<i class='fas fa-bars'></i>");
    } else {
      $(".item").addClass("active");
      $(this).find("a").html("<i class='fas fa-times'></i>");
    }
  });
});
